import { CommissionPayoutRate } from '@/types'

export const calcCommission = (amount: number, rates: CommissionPayoutRate[]) =>
  rates.reduce((prev, rate) => {
    const startAmount = rate.startAmount || 0
    const endAmount = rate.endAmount ?? 999999999999999
    const payoutRate = rate.payoutRate || 0

    const itemAmount = Math.max(0, Math.min(amount, endAmount) - startAmount)
    const payout = itemAmount * payoutRate

    return prev + payout
  }, 0)
