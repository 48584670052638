












































































































import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'

import JQuotaProgress from '@/components/controls/JQuotaProgress.vue'

import { calcCommission } from '@/utils/commission-calcs'

import { CommissionPayoutRate, CommissionQuotaAttainmentGroups } from '@/types'

type Query = {
  [key: string]: string | number
}

// TODO: fix types
type Item = CommissionQuotaAttainmentGroups & {
  commissionComponentGroup: {
    id: number
  }
  items: CommissionQuotaAttainmentGroups[]
}

export default Vue.extend({
  components: {
    JQuotaProgress,
  },
  props: {
    group: Object,
    item: Object as PropType<Item>,

    outlined: Boolean,
  },
  data: () => ({}),
  computed: {
    isYearComplete(): boolean {
      return (
        Math.round(10000 * this.item.ytdQuotaAmount) >=
        Math.round(10000 * this.item.commissionQuotaAmount)
      )
    },
    unpostedQuery(): Query {
      return {
        companyId: this.group.company.id,
        salesAreaId: this.group.salesArea.id,
        salespersonId: this.group.salesperson.id,
        commissionQuotaPeriodId: this.group.commissionQuotaPeriod.id,
        currency: this.group.currency,
        commissionComponentGroupId: this.item.commissionComponentGroup.id,
      }
    },
    postedQuery(): Query {
      return {
        ...this.unpostedQuery,
        isPosted: 1,
      }
    },
    postedRoute(): RawLocation {
      return {
        name: 'order-details',
        query: Object.entries(this.postedQuery).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value.toString(),
          }),
          {}
        ),
      }
    },
    unpostedRoute(): RawLocation {
      return {
        name: 'order-details',
        query: Object.entries(this.unpostedQuery).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value.toString(),
          }),
          {}
        ),
      }
    },
    commissionAmount(): number {
      return this.item.items.reduce(
        (prev, item) =>
          prev +
          calcCommission(
            item.postedAmount,
            this.getCommissionPayoutRates(item.commissionComponent.id)
          ),
        0
      )
    },
    estCommissionAmount(): number {
      return this.item.items.reduce(
        (prev, item) =>
          prev +
          calcCommission(
            item.estPostedAmount,
            this.getCommissionPayoutRates(item.commissionComponent.id)
          ),
        0
      )
    },
  },
  methods: {
    getCommissionPayoutRates(
      commissionComponentId: number
    ): CommissionPayoutRate[] {
      if (!this.item) return []

      return ((this.$store.getters.commissionPayoutRates ||
        []) as CommissionPayoutRate[]).filter(
        z =>
          z.companyId === this.group.company.id &&
          z.salesAreaId === this.group.salesArea.id &&
          z.salespersonId === this.group.salesperson.id &&
          z.commissionQuotaPeriodId === this.group.commissionQuotaPeriod.id &&
          z.currency === this.group.currency &&
          z.commissionComponentId === commissionComponentId
      )
    },
  },
})
