



















import Vue from 'vue'

import CommissionQuotaAttainmentGroups from '../CommissionQuotaAttainmentGroups.vue'
import CommissionQuotaAttainmentCommissionComponentGroup from './partials/CommissionQuotaAttainmentCommissionComponentGroup.vue'
import CommissionQuotaAttainmentCommissionComponent from './partials/CommissionQuotaAttainmentCommissionComponent.vue'

export default Vue.extend({
  metaInfo: {
    title: 'Attainment - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    CommissionQuotaAttainmentCommissionComponentGroup,
    CommissionQuotaAttainmentCommissionComponent,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    outlined: true,
  }),
  methods: {},
})
