












































































































import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'

import JQuotaProgress from '@/components/controls/JQuotaProgress.vue'

import { calcCommission } from '@/utils/commission-calcs'

import { CommissionPayoutRate, CommissionQuotaAttainmentGroups } from '@/types'

type Query = {
  [key: string]: string | number
}

type Item = CommissionQuotaAttainmentGroups

export default Vue.extend({
  components: {
    JQuotaProgress,
  },
  props: {
    item: Object as PropType<Item>,

    outlined: Boolean,
  },
  data: () => ({}),
  computed: {
    unpostedQuery(): Query {
      return {
        companyId: this.item.company.id,
        salesAreaId: this.item.salesArea.id,
        salespersonId: this.item.salesperson.id,
        commissionQuotaPeriodId: this.item.commissionQuotaPeriod.id,
        currency: this.item.currency,
        commissionComponentId: this.item.commissionComponent.id,
      }
    },
    postedQuery(): Query {
      return {
        ...this.unpostedQuery,
        isPosted: 1,
      }
    },
    postedRoute(): RawLocation {
      return {
        name: 'order-details',
        query: Object.entries(this.postedQuery).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value.toString(),
          }),
          {}
        ),
      }
    },
    unpostedRoute(): RawLocation {
      return {
        name: 'order-details',
        query: Object.entries(this.unpostedQuery).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value.toString(),
          }),
          {}
        ),
      }
    },
    commissionPayoutRates(): CommissionPayoutRate[] {
      if (!this.item) return []

      return ((this.$store.getters.commissionPayoutRates ||
        []) as CommissionPayoutRate[]).filter(
        z =>
          z.companyId === this.item.company.id &&
          z.salesAreaId === this.item.salesArea.id &&
          z.salespersonId === this.item.salesperson.id &&
          z.commissionQuotaPeriodId === this.item.commissionQuotaPeriod.id &&
          z.commissionComponentId === this.item.commissionComponent.id &&
          z.currency === this.item.currency
      )
    },
    commissionAmount(): number {
      return calcCommission(this.item.postedAmount, this.commissionPayoutRates)
    },
    estCommissionAmount(): number {
      return calcCommission(
        this.item.estPostedAmount,
        this.commissionPayoutRates
      )
    },
  },
  methods: {},
})
