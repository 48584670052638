


























































































































import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'

import JQuotaProgressTooltip from '@/components/controls/JQuotaProgressTooltip.vue'

export default Vue.extend({
  components: {
    JQuotaProgressTooltip,
  },
  props: {
    label: String,
    quotaLabel: String,
    currency: String,
    quota: Number,
    sales: Number,

    to: [String, Object] as PropType<RawLocation>,

    color: {
      type: String,
      default: 'primary',
    },
    successColor: {
      type: String,
      default: 'success',
    },
    backgroundColor: {
      type: String,
      default: 'rgba(158, 158, 158, 0.4)',
    },
    quotaLineWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    difference(): number {
      return this.sales - this.quota
    },
    percent(): number {
      return this.sales / this.quota
    },
    isQuotaMet(): boolean {
      return !!this.quota && this.sales >= this.quota
    },
  },
  methods: {
    calcColor(color: string): string {
      const themeColor = this.$vuetify.theme.currentTheme[color]
      if (themeColor) return themeColor.toString()
      return color
    },
  },
})
