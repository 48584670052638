var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"j-quota-progress"},[_c('div',{staticClass:"j-quota-progress-bar",style:({
        'background-color': _vm.calcColor(_vm.backgroundColor),
      })},[_c('router-link',{staticClass:"j-quota-progress-bar-progress clickable",style:({
          width: Math.min(1, _vm.percent) * 100 + '%',
          'background-color': _vm.calcColor(_vm.color),
        }),attrs:{"to":_vm.to || {}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"fill-height"},'div',attrs,false),on))]}}])},[_c('j-quota-progress-tooltip',{attrs:{"label":_vm.label,"quota-label":_vm.quotaLabel,"quota":_vm.quota,"sales":_vm.sales,"currency":_vm.currency}})],1)],1)],1),(_vm.isQuotaMet)?_c('router-link',{staticClass:"j-quota-progress-bar-progress-over clickable",style:({
        left: ("calc(" + ((_vm.quota / _vm.sales) * 100 + '%') + " + 1px)"),
        right: 0,
        'background-color': _vm.calcColor(_vm.successColor),
      }),attrs:{"to":_vm.to || {}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"fill-height"},'div',attrs,false),on))]}}],null,false,3060158604)},[_c('j-quota-progress-tooltip',{attrs:{"label":_vm.label,"quota-label":_vm.quotaLabel,"quota":_vm.quota,"sales":_vm.sales,"currency":_vm.currency}})],1)],1):_vm._e(),_c('div',{staticClass:"j-quota-progress-sales-label"},[_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.sales,_vm.currency)))])]),_c('div',{staticClass:"j-quota-progress-name-label"},[_c('div',[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"j-quota-progress-quota-label",class:{
        'nudge-left':
          !(_vm.isQuotaMet && _vm.quota / _vm.sales > 0.5) || _vm.quota / _vm.sales > 0.666667,
      },style:({
        left:
          _vm.isQuotaMet && _vm.quota / _vm.sales > 0.5
            ? (_vm.quota / _vm.sales) * 100 + '%'
            : '100%',
      })},[_c('div',[_vm._v("Quota: "+_vm._s(_vm._f("currency")(_vm.quota,_vm.currency)))])]),_c('div',{staticClass:"j-quota-progress-percent-label nudge-top-half",class:{
        dark: _vm.isQuotaMet ? true : _vm.percent > 0.5,
        'nudge-left':
          (_vm.isQuotaMet && _vm.quota / _vm.sales > 0.5) ||
          (!_vm.isQuotaMet && _vm.percent > 0.5),
      },style:({
        left: _vm.isQuotaMet ? (_vm.quota / _vm.sales) * 100 + '%' : _vm.percent * 100 + '%',
      })},[_c('div',[_vm._v(_vm._s(_vm._f("percent")(_vm.percent,1)))])]),_c('div',{staticClass:"j-quota-progress-diff-label"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.difference,_vm.currency, { signDisplay: 'exceptZero' }))+" ")])]),(_vm.isQuotaMet)?_c('div',{staticClass:"j-quota-progress-quota-line",style:({
        left: (_vm.quota / _vm.sales) * 100 + '%',
        width: _vm.quotaLineWidth + 'px',
      })}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }