























import Vue from 'vue'

export default Vue.extend({
  props: {
    label: String,
    quotaLabel: String,
    quota: Number,
    sales: Number,
    currency: String,
  },
  computed: {
    difference(): number {
      return this.sales - this.quota
    },
    percent(): number {
      return this.sales / this.quota
    },
  },
})
